const convertToSlug = text => {
  const slug = text
    ?.toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z-]/g, '')

  return slug
}

export { convertToSlug }
