import { SEND_APPLINK_WHATSAPP_ENDPOINT } from '@components/HomePage/DownloadAppModal/constants'
import { getConfig } from '@configs/evnConfig'
import { gmcTimeOut } from '@configs/reward.config'
import { fetchWithTimeout } from '@utils/utils'

const API_PATHS = {
  GET_USER_DETAILS: '/api/user/details/',
  IS_GMC_USER: userId => `/users/v2/${userId}/policies/gmc/check`,
}

export default class UserServiceApi {
  static async getUserDetails() {
    let result
    try {
      const response = await fetch(API_PATHS.GET_USER_DETAILS, { next: { revalidate: 0 }, credentials: 'include' })
      result = await response.json()
    } catch (ex) {
      console.log('user service error', ex)
    }
    return result
  }

  static async isGmcUser(userId) {
    const config = process.env.ACKO_ENV
    try {
      const response = await fetchWithTimeout(
        `${getConfig(config)?.healthUserServiceUrl}${API_PATHS.IS_GMC_USER(userId)}`,
        {
          timeout: gmcTimeOut[config || 'master'],
        }
      )
      if (response.ok) {
        const data = await response.json()
        return data
      }
    } catch (ex) {
      console.log('health gmc user service error', ex)
    }
    return false
  }

  static async sendWhatsAppCommunication({ phoneNumber = '', userId = '', componentType, token }) {
    let result
    try {
      const body = {
        componentType,
        ...(userId && { user_id: userId }),
        ...(phoneNumber && { phone: phoneNumber }),
      }
      result = await fetch(SEND_APPLINK_WHATSAPP_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-captcha-token': token,
        },
        body: JSON.stringify(body),
      })
    } catch (ex) {
      console.log('Whatsapp communication failed')
      throw new Error('Unable to send whatsapp link')
    }
    return result
  }
}
