/* eslint-disable react/no-danger */
import React from 'react'
import { convertToSlug } from 'src/utils/IdGenerator'
import { getFooterEntries } from '@services/footerService'
import ErrorBoundary from '@components/ErrorBoundary/errorBoundary'
import { baseUrl } from '@components/Product/ProductHead/utils'
import { parsePromiseResponse } from '@utils/promiseResponse'
import assetLoader from '@utils/internalImageLoader'
import ClickableImage from '@components/ClickableImage/ClickableImage'
import styles from './DynamicInterlinking.module.scss'

const getProductPageUrls = async pageFooterTagName => {
  const res = await getFooterEntries({
    contentType: 'product-pages',
    pageFooterTagName,
    draft: process.env.ACKO_ENV === 'prod' ? 'live' : 'preview',
    sort: 'createdAt',
    limit: 50,
  })
  return res
}

const getProductUrls = async pageFooterTagName => {
  const res = await getFooterEntries({
    contentType: 'products',
    pageFooterTagName,
    draft: process.env.ACKO_ENV === 'prod' ? 'live' : 'preview',
    sort: 'createdAt',
    limit: 50,
  })
  return res
}

const getblogsUrls = async pageFooterTagName => {
  const res = await getFooterEntries({
    contentType: 'blogs',
    pageFooterTagName,
    draft: process.env.ACKO_ENV === 'prod' ? 'live' : 'preview',
    sort: 'createdAt',
    limit: 50,
  })
  return res
}

const getDynamicUrls = async footerHeadings => {
  const dynamicUrls: any = []

  for (let i = 0; i < footerHeadings.length; i++) {
    const responses = await Promise.allSettled([
      await getProductPageUrls(footerHeadings[i]?.attributes?.pageFooterTagName),
      await getProductUrls(footerHeadings[i]?.attributes?.pageFooterTagName),
      await getblogsUrls(footerHeadings[i]?.attributes?.pageFooterTagName),
    ])
    const productPageUrls = parsePromiseResponse(responses?.[0])
    const productUrls = parsePromiseResponse(responses?.[1])
    const blogPageUrls = parsePromiseResponse(responses?.[2])

    let urls: any = []

    if (Array.isArray(productUrls) && productUrls?.length) {
      urls = [...productUrls]
    }
    if (Array.isArray(productPageUrls) && productPageUrls?.length) {
      urls = [...urls, ...productPageUrls]
    }
    if (Array.isArray(blogPageUrls) && blogPageUrls?.length) {
      urls = [...urls, ...blogPageUrls]
    }

    const formatUrls = urls?.map(url => ({
      url: url?.attributes?.url,
      name: url?.attributes?.anchorTextForPageFooter,
    }))
    dynamicUrls.push({
      heading: footerHeadings[i]?.attributes?.pageFooterTableName,
      urls: formatUrls,
    })
  }
  return dynamicUrls
}

const DynamicInterlinking = async ({ data }) => {
  const heading = data?.data?.attributes?.DynamicInterlinkingHeading
  const footerHeadings = data?.data?.attributes?.Content?.data
  const dynamicUrls = await getDynamicUrls(footerHeadings)

  if (!dynamicUrls?.length) {
    return null
  }

  // Extract the h2 content and headerTabName
  const h2Regex = /<h2[^>]*>(.*?)<\/h2>/
  const matches = heading?.match(h2Regex)
  const h2Content = matches ? matches[1] : null
  const idContent = data?.data?.attributes?.headerTabName || h2Content

  return (
    <ErrorBoundary data={data}>
      {dynamicUrls[0]?.heading && dynamicUrls[0]?.urls && (
        <div className={styles.dynamicInterlinking} id={convertToSlug(idContent)}>
          <div dangerouslySetInnerHTML={{ __html: heading }} className={styles.dynamicInterlinkingHeading} />
          {dynamicUrls?.map((item, index) => {
            if (item?.urls?.length === 0) return null
            return (
              <div className={styles.sectionStyle}>
                <div key={index}>
                  <h3>{item?.heading}</h3>
                  <div className={styles.linkSection}>
                    {item?.urls?.map((url, ind) => (
                      <div className={styles.urlTag}>
                        <a href={`${baseUrl}${url.url}`} target="_blank" key={ind}>
                          {url.name}
                        </a>
                        <ClickableImage
                          src={`${assetLoader()}/images/linktag.png`}
                          width={20}
                          height={20}
                          alt="Click Link"
                          imageStyles={styles.imge}
                          loading="lazy"
                          redirectionUrl={`${baseUrl}${url.url}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </ErrorBoundary>
  )
}

export default DynamicInterlinking
