/* eslint-disable no-console */

'use server'

import { baseURL, headers } from '@helpers/strapiHelpers'

interface FooterEntriesOptions {
  contentType: string
  pageFooterTagName: string
  draft?: string
  sort?: string
  limit?: Number
  deepPopulate?: boolean
  fieldsToPopulate?: string[]
  pageStart?: number
  total?: boolean
}

export const getFooterEntries = async (options: FooterEntriesOptions) => {
  const { contentType, pageFooterTagName, draft, sort, limit, deepPopulate, fieldsToPopulate, pageStart, total } =
    options

  let res
  let fieldsToPopulateString = ''
  fieldsToPopulate?.map((value, index) => {
    fieldsToPopulateString += `populate[${index}]=${value}&`
  })
  const filters = `filters[$and][0][$or][0][pageTags][$containsi]=,${pageFooterTagName}&filters[$and][0][$or][1][pageTags][$containsi]= ${pageFooterTagName}&filters[$and][0][$or][2][pageTags][$startsWithi]=${pageFooterTagName}&filters[$and][1][$or][0][pageTags][$containsi]=${pageFooterTagName},&filters[$and][1][$or][1][pageTags][$containsi]=${pageFooterTagName} &filters[$and][1][$or][2][pageTags][$endsWithi]=${pageFooterTagName}`
  const params = `${filters}${fieldsToPopulateString}${deepPopulate ? '&populate=deep' : ''}${
    sort ? `&sort=${sort}:desc` : ''
  }${limit ? `&pagination[start]=${pageStart || 0}&pagination[limit]=${limit}` : ''}${
    draft ? `&publicationState=${draft}` : ''
  }${total ? '&pagination[withCount]=' + 'true' : ''}`

  try {
    const response = await fetch(`${baseURL}/${contentType}?${params}`, {
      method: 'GET',
      headers,
    })

    if (!response.ok) {
    // TODO   newrelic.noticeError(new Error(`Dynamic Interlinking HTTP error Status: ${response.status}`))
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const responseData = await response.json()
    res = responseData.data
  } catch (error) {
    // TODO newrelic.noticeError(new Error('Dynamic Interlinking Fetch Error'))
    console.error('Fetch error:', error)
  }

  return res
}
