'use client'

import Image from 'next/image'

const ClickableImage = ({ src, width, loading, height, alt, redirectionUrl, imageStyles = '', priority = false }) => {
  const onImageClick = () => {
    window.open(redirectionUrl)
  }
  return (
    <Image
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={imageStyles}
      priority={priority}
      onClick={onImageClick}
      loading={loading}
    />
  )
}

export default ClickableImage
