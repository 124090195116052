// import NColumnGrid from '@components/NColumnGrid/NColumnGrid'
// import OneColumnWidth from '@components/OneColumnWidth/onecolumnwidth'
// import Table from '@components/Table/table'
// import Video from '@components/Video/Video'
// import Accordion from '@components/Accordian/Accordian'
import DynamicInterlinking from '@components/DynamicInterlinking/DynamicInterlinking'
// import Quote from '@components/Quote/renderQuote'
// import Author from '@components/Author/author'
// import GoogleRating from '@components/GoogleRating/googleRating'
// import JoinMillion from '@components/JoinMillions/joinMillion'
// import Testimonial from '@components/Testimonial/testimonial'
// import StepCards2Column from '@components/StepCards2Column/stepCards2Column'

import dynamic from 'next/dynamic'

export const RELATION_VS_COMPONENTS = {
  'relation.product-page-accordian': dynamic(() => import('@components/Accordian/Accordian'), { ssr: true }),
  'relation.product-page-swc': dynamic(() => import('@components/OneColumnWidth/onecolumnwidth'), { ssr: true }),
  'relation.pp-jmd': dynamic(() => import('@components/JoinMillions/joinMillion'), { ssr: true }),
  'relation.product-page-table': dynamic(() => import('@components/Table/table'), { ssr: true }),
  'relation.pp-video': dynamic(() => import('@components/Video/Video'), { ssr: true }),
  'relation.product-page-n-grid': dynamic(() => import('@components/NColumnGrid/NColumnGrid'), { ssr: true }),
  'relation.product-page-n-step': dynamic(() => import('@components/StepCards2Column/stepCards2Column'), { ssr: true }),
  'relation.product-page-di': DynamicInterlinking,
  'relation.product-page-quote': dynamic(() => import('@components/Quote/renderQuote'), { ssr: true }),
  'relation.product-page-author': dynamic(() => import('@components/Author/author'), { ssr: true }),
  'relation.product-page-grw': dynamic(() => import('@components/GoogleRating/googleRating'), { ssr: true }),
  'relation.pp-testimonial': dynamic(() => import('@components/Testimonial/testimonial'), { ssr: true }),
}

export const widgetsProductType = {
  'travel-seo-widget': 'Travel',
  'travel-sem-widget': 'Travel',
  'health-seo-widget': 'Retail health',
  'life-seo-widget': 'Life',
  'car-seo-widget': 'auto-car',
  'bike-seo-widget': 'auto-bike',
}
