/* eslint-disable @typescript-eslint/prefer-optional-chain */

'use client'

/* eslint-disable  no-unneeded-ternary */

import { widgetsProductType } from '@configs/components.config'
import { getCookie } from 'cookies-next'
import { getTrackerId, isLoggedInUtil } from '@utils/utils'
import { TENANT_TYPE } from '@components/Ackodrive/common/constants'
import { userUserDataFunc } from '@utils/userUserData'
import isMobile from 'ismobilejs'
import { Lob } from 'src/enums/Lob'
import { getPlatform } from '@configs/platform.config'
import { getR2d2Payload } from './r2d2Payload'

export const getDevicePlatform = () => {
  const isMobileDevice = isMobile(navigator.userAgent).any
  return !isMobileDevice ? 'web' : getCookie('webview') ? 'app' : 'mweb'
}

export const segmentEventCommonPropFunc = async propertiesObject => {
  const { productWidgetData, contentfulTagNames } = propertiesObject
  const contentfulTagNamesArray = contentfulTagNames ? contentfulTagNames?.split(',') : []

  const isLoggedIn: boolean = getCookie('user_id') !== '' && typeof getCookie('user_id') === 'string'
  const userData: any = await userUserDataFunc(isLoggedIn)
  const pageWidget = productWidgetData?.Content?.data?.attributes?.widget
  const pageTitle = productWidgetData?.Content?.data?.attributes?.productWidgetMainHeading

  const userTrackerId = getTrackerId(TENANT_TYPE.ACKOSEO)
  const additionalProps = {
    contentful_tag_names: contentfulTagNamesArray,
    widget_type: 'SEO revamp widget',
    tracker_id: userTrackerId,
  }
  let path = ''
  let source = ''

  // Check if window is defined (i.e., if the code is running in a browser environment)
  if (typeof window !== 'undefined') {
    path = window.location.href || ''
    source = document.referrer || ''
  }

  // Parse the query string from window.location.search if window is defined
  const queryParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null

  return {
    address: userData?.addresses?.[0] || {},
    dob: userData?.date_of_birth || '',
    email: userData?.email || '',
    from_page: source,
    journey: pageTitle || '',
    path,
    product: widgetsProductType[pageWidget] || pageTitle || '',
    page: path,
    phone: userData?.phone || '',
    platform: getDevicePlatform(),
    source,
    utm_source: queryParams ? queryParams.get('utm_source') || '' : '',
    utm_campaign: queryParams ? queryParams.get('utm_campaign') || '' : '',
    utm_content: queryParams ? queryParams.get('utm_content') || '' : '',
    utm_medium: queryParams ? queryParams.get('utm_medium') || '' : '',
    url: path,
    ...additionalProps,
  }
}

enum Platform {
  App = 'app',
  MWeb = 'mweb',
  Web = 'web',
}

export const fuelSegmentEventCommonPropFunc = async (additionalProps = {}) => {
  const isMobileDevice = isMobile(navigator.userAgent).any
  const isLoggedIn: boolean = getCookie('user_id') !== '' && typeof getCookie('user_id') === 'string'
  const userData: any = await userUserDataFunc(isLoggedIn)
  const isWebView = getCookie('webview')

  function getPlatform(): Platform {
    if (!isMobileDevice) {
      return Platform.Web
    } else if (isWebView) {
      return Platform.App
    } else {
      return Platform.MWeb
    }
  }

  return {
    address: (userData?.address && userData?.addresses[0]) || {},
    dob: userData?.date_of_birth || '',
    email: userData?.email || '',
    from_page: (typeof document !== 'undefined' && document.referrer) || '',
    path: typeof window !== 'undefined' && window?.location?.href,
    product: 'Fuel Price',
    page: typeof window !== 'undefined' && window?.location?.href,
    platform: getPlatform(),
    source: (typeof document !== 'undefined' && document.referrer) || '',
    ...additionalProps,
  }
}

export const semAutoR2d2properties = product => ({
  googleRecaptcha: {},
  is_renewable: false,
  journey: product === Lob.CAR ? 'old_car' : 'old_bike',
  lob: 'direct',
  pa_cover: false,
  screen: 'registration_number_screen',
  tenure: 1,
})

const lobSegmentEkind = {
  [Lob.CAR]: 'landing_screen_loaded',
  [Lob.BIKE]: 'bike_landing_screen_loaded',
}
const lobSegmentJourney = {
  [Lob.CAR]: 'buy fresh car',
  [Lob.BIKE]: 'buy fresh bike',
}

const getAutoSegmentEventProperties = (reqCookie, isSemPage, product) => {
  const path = window?.location?.pathname || ''
  const url = window?.location?.href || ''
  const loggedIn = isLoggedInUtil(reqCookie)

  return {
    landing_page: path,
    language: 'en',
    mobile_verified: loggedIn,
    page_link: url,
    partner_widget: false,
    ...(isSemPage ? { journey: lobSegmentJourney[product] } : {}),
    ...getR2d2Payload({
      isSemPage,
      ekind: isSemPage ? lobSegmentEkind[product] : 'product_page_loaded',
      edata: isSemPage ? semAutoR2d2properties(product) : {},
      odata: { product },
    }),
  }
}

export const lobSegmentEventProperties = {
  [Lob.CAR]: (reqCookie, isSemPage) => getAutoSegmentEventProperties(reqCookie, isSemPage, Lob.CAR),
  [Lob.BIKE]: (reqCookie, isSemPage) => getAutoSegmentEventProperties(reqCookie, isSemPage, Lob.BIKE),
}

export const segmentEventCommonPropFuncAckoDrive = ({ pageType }) => {
  const isMobileDevice = isMobile(navigator.userAgent).any
  const refereshAccessToken: any = getCookie('ackodrive_tokens') || '{}'
  const isLoggedIn = Object.keys(JSON.parse(decodeURIComponent(refereshAccessToken))).length

  const userTrackerId = getCookie('eventTrackerUserId')

  // extract data from localStorage

  const fullName = localStorage.getItem('login_full_name')
  const email = localStorage.getItem('login_email_id')
  const mobileNumber = localStorage.getItem('mobileNumber') || ''

  let path = ''
  let source = ''

  // Check if window is defined (i.e., if the code is running in a browser environment)
  if (typeof window !== 'undefined') {
    path = window.location.href || ''
    source = document.referrer || ''
  }

  // Parse the query string from window.location.search if window is defined
  const queryParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null

  return {
    customer_name: fullName || '',
    email: email || '',
    journey: 'Home Page' || '',
    logged_in: isLoggedIn ? true : false,
    page: path,
    pageType: pageType || '',
    path,
    phone: mobileNumber,
    platform: !isMobileDevice ? 'web' : getCookie('webview') ? 'app' : 'mweb',
    referrer: source,
    resource_id: userTrackerId,
    search: '',
    source,
    title: '',
    url: path,
    utm_source: queryParams ? queryParams.get('utm_source') || '' : '',
    utm_campaign: queryParams ? queryParams.get('utm_campaign') || '' : '',
    utm_content: queryParams ? queryParams.get('utm_content') || '' : '',
    utm_medium: queryParams ? queryParams.get('utm_medium') || '' : '',
  }
}
export const homePageCommonProps = () => ({
  platform: getDevicePlatform(),
  experiment_name: 'wrapper_v1',
})

export const rewardsPageProps = () => ({ platform: getDevicePlatform(), experiment_name: 'rewards_v2' })

export const getRewardsPagePropsObject = (isApp, isMobile) => ({
  platform: getPlatform({ isMobile, isApp }),
  experiment_name: 'rewards_v2',
})
