'use client'

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { Component, ErrorInfo, ReactNode } from 'react'

interface ErrorBoundaryProps {
  data?: any // Adjust the type according to your data structure
  children: ReactNode // Add children prop
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
  data: any
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null, data: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error,
      errorInfo,
    })
    // You can log the error to an error reporting service here, e.g., Sentry
    // logErrorToMyService(error, errorInfo);
  }

  render(): ReactNode {
    const errorStackLines = this.state.errorInfo?.componentStack?.split('\n').slice(0, 2)

    if (this.state?.hasError) {
      // Render fallback UI
      return (
        <div style={{ padding: '6rem 1.5rem', textAlign: 'center', width: '70%', margin: '0 auto' }}>
          <h2>Something went wrong!</h2>
          <p>Error: {this.state.error?.toString()}</p>
          <p>Error Info: {errorStackLines?.join('\n')}</p>
          <p>For this Section Data: {JSON.stringify(this.props.data)}</p>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
