import { ClientName } from 'src/enums/ClientName'
import { getConfig } from './evnConfig'

const rewardBearerTokenBody = {
  local: {
    client_id: 'acko_home_local',
    grant_type: 'client_credentials',
    client_secret: process.env.OAUTH_CLIENT_SECRET,
  },
  dev: {
    client_id: 'acko_home_dev',
    grant_type: 'client_credentials',
    client_secret: process.env.OAUTH_CLIENT_SECRET,
  },
  prod: {
    client_id: 'rewards_prod__acko__home',
    grant_type: 'client_credentials',
    client_secret: process.env.OAUTH_CLIENT_SECRET,
  },
  'pre-prod': {
    client_id: 'rewards_prod__acko__home',
    grant_type: 'client_credentials',
    client_secret: process.env.OAUTH_CLIENT_SECRET,
  },
  master: {
    client_id: 'acko_home_dev',
    grant_type: 'client_credentials',
    client_secret: process.env.OAUTH_CLIENT_SECRET,
  },
}

export const tokenExpiryTime = {
  dev: 240000,
  master: 240000,
  uat: 240000,
  prod: 240000,
  'pre-prod': 240000,
}
export const gmcTimeOut = {
  dev: 5000,
  master: 5000,
  prod: 1000,
  'pre-prod': 1000,
  local: 5000,
}

export const ClientNameConfig = {
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.health}`]: 'health',
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.car}`]: 'car',
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.life}`]: 'life',
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.travel}`]: 'travel',
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.ackoDrive}`]: 'ackoDrive',
  [`${getConfig(process.env.ACKO_ENV)?.rewardClientNames?.ackoDriveService}`]: 'ackoDriveService',
}

export const couponsListData = {
  [ClientName.BIKE]: {
    heading: 'For bike insurance',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Scooter_Light_54511a432c.svg',
    modalCtaText: 'Get bike insurance',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoBaseUrl}/gi/lp/new-bike?utm_source=coupons_atl&utm_medium=coupons_atl&utm_campaign=coupons_atl`,
  },
  [ClientName.CAR]: {
    heading: 'For car insurance',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Car_small_efaeeb3199.svg',
    modalCtaText: 'Get car insurance',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoBaseUrl}/gi/lp/new-car-comprehensive-hi?utm_source=coupons_atl&utm_medium=coupons_atl&utm_campaign=coupons_atl`,
  },
  [ClientName.HEALTH]: {
    heading: 'For health insurance',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Health_Insurance_c6bd259e79.svg',
    modalCtaText: 'Get health insurance',
    ctaLink: `${getConfig(process.env.ACKO_ENV)?.ackoBaseUrl}/gi/p/health/segmentV2`,
  },
  [ClientName.TRAVEL]: {
    heading: 'For travel insurance',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Travel_923e9f1555.svg',
    modalCtaText: 'Get travel insurance',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoBaseUrl}/p/travel/international-insurance?utm_source=rewards-section&platform=app_ios&hide_app_bar=true`,
  },
  [ClientName.LIFE]: {
    heading: 'For life insurance',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Life_insurance_d90d39919f.svg',
    modalCtaText: 'Get life insurance',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoBaseUrl}/life/p/semLifeBuy?utm_source=coupons_atl&utm_medium=health&utm_campaign=acko_rewards_page`,
  },
  [ClientName.ACKO_DRIVE]: {
    heading: 'For a new car',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Ackodrive_new_car_38bfe24a59.svg',
    modalCtaText: 'Get a new car',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoDriveBaseUrl}/cars/?utm_source=acko&utm_medium=app&utm_campaign=ATLhealth`,
  },
  [ClientName.ACKO_DRIVE_SERVICE]: {
    heading: 'For car servicing',
    subHeading: 'You can redeem all your coupons at once for maximum savings',
    iconUrl: 'https://acko-cms.ackoassets.com/Ackodrive_service_de0fae81a7.svg',
    modalCtaText: 'Book car servicing',
    ctaLink: `${getConfig(process.env.ACKO_ENV)
      ?.ackoBaseUrl}/garage-storefront/ad-services?utm_source=acko&utm_medium=app_rewards&utm_campaign=ATL`,
  },
}

export const isAckoDriveReward = clientName =>
  [ClientName.ACKO_DRIVE, ClientName.ACKO_DRIVE_SERVICE].includes(clientName)

export const getRewardBearerTokenBody = envValue => rewardBearerTokenBody[envValue]
