const assetLoader = () => {
  let endpoint
  if (process.env.ACKO_ENV === 'master') {
    endpoint = 'https://acko-seo-assets.ackoassets.com/_next_static'
  } else if (process.env.ACKO_ENV === 'prod' || process.env.ACKO_ENV === 'pre-prod') {
    endpoint = 'https://acko-seo-prod-assets.ackoassets.com/_next_static'
  } else {
    endpoint = 'https://carbon.ackodev.com'
  }
  return endpoint
}

export const cmsLoader = ({ src }) => src

export default assetLoader
