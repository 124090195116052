'use client'

/* eslint-disable no-console */

import { useState, useEffect } from 'react'
import UserServiceApi from '@services/userService'

interface UserApiResponse {
  addresses: string[]
  amazon_prime: boolean
  date_of_birth: null | string
  email: string
  name: string
  phone: string
  gender: string
  whatsapp_optin: boolean
  is_amazon: boolean
}

export function useUserData(isLoggedIn) {
  const [userData, setUserData] = useState<UserApiResponse | undefined>({
    addresses: [],
    amazon_prime: false,
    date_of_birth: null,
    email: '',
    name: '',
    phone: '',
    gender: '',
    whatsapp_optin: false,
    is_amazon: false,
  })

  useEffect(() => {
    if (isLoggedIn) {
      UserServiceApi.getUserDetails()
        .then(res => {
          setUserData(res)
        })
        .catch(err => {
          console.log('CATCH USER DETAILS ERROR : ', err)
        })
    }
  }, [isLoggedIn])

  return userData
}

export async function userUserDataFunc(isLoggedIn) {
  const userData = {
    addresses: [],
    amazon_prime: false,
    date_of_birth: null,
    email: '',
    name: '',
    phone: '',
    gender: '',
    whatsapp_optin: false,
    is_amazon: false,
  }
  if (isLoggedIn) {
    try {
      const data = await UserServiceApi.getUserDetails()
      return data
    } catch (ex) {
      console.log('CATCH USER DETAILS ERROR : ', ex)
    }
  }
  return userData
}
