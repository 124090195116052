const PROMISE_STATES = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
}

export const parsePromiseResponse = response => {
  if (response.status === PROMISE_STATES.FULFILLED) {
    return response?.value
  }
  if (response.status === PROMISE_STATES.REJECTED) {
    console.error('Request failed with reason:', response.reason)
    throw new Error(`Request failed with reason: ${response.reason}`)
  }
}
