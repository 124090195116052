export const ctaProductLink = {
  mobile: 'ctaLinkMWeb',
  desktop: 'ctaLinkDesktop',
}

export const PLATFORM = {
  DESKTOP: 'web',
  MOBILE: 'mweb',
  APP: 'app',
}

export const PLATFORM_CONFIG = {
  [PLATFORM.DESKTOP]: {
    rewards: {
      heading: ({ webHeading }) => webHeading,
      subHeading: ({ webSubHeading }) => webSubHeading,
      buttonText: ({ desktopButtonText }) => desktopButtonText,
    },
  },
  [PLATFORM.MOBILE]: {
    rewards: {
      heading: ({ webHeading }) => webHeading,
      subHeading: ({ webSubHeading }) => webSubHeading,
      buttonText: ({ mwebButtonText }) => mwebButtonText,
    },
  },
  [PLATFORM.APP]: {
    rewards: {
      heading: ({ appHeading }) => appHeading,
      subHeading: ({ appSubHeading }) => appSubHeading,
      buttonText: () => '',
    },
  },
}

export const getPlatform = ({ isMobile, isApp }) =>
  isApp ? PLATFORM.APP : isMobile ? PLATFORM.MOBILE : PLATFORM.DESKTOP
